<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card light>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5" style="word-break: break-word;">
                    Alterar Vencimento - {{ value.charge_id }}
                </span>
                <v-spacer/>
            </v-card-title>
            <v-card-text align="center" justify="center" class="mt-4" style="height: 80px;">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Novo Vencimento"
                            type="date"
                            v-model="vencimento"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer/>
                <v-btn color="success" @click="confirmar">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'CobrancaAlterarVencimento',

    props: ['visible', 'value'],

    data: () => ({
        vencimento: null,
    }),

    mounted() {
        this.vencimento = this.moment().format('YYYY-MM-DD');
    },

    methods: {
        confirmar() {
            if (!this.vencimento) {
                alert('Preencha todos os campos');
                return;
            }

            if (!this.checkPermission('zapercontrol_empresas_historico_cobrancas_alterar_vencimento')) {
                alert('Usuário sem permissão');
                return;
            }

            const data = {
                empresa_id: this.$route.params.emp,
                vencimento: this.vencimento,
            };

            this.$root.$emit('loading', true);
            this.$http.put(`cobrancas/${this.value.charge_id}/alterar-vencimento`, data).then(resp => {
                if (resp.data?.data?.type == 'warning') {
                    alert(resp.data.data.msg)
                    return;
                }

                alert('Vencimento alterado', 'warning');

                this.$emit('close');
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.$root.$emit('loading', false)));
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
